import Vue from 'vue';
import VueRouter from 'vue-router';

import pt from '@/plugins/router/langs/pt.js';
import es from '@/plugins/router/langs/es.js';
import en from '@/plugins/router/langs/en.js';

import { globalConfigs } from '@/../app.config.json';

// PROPRIEDADES

let routes = null;
let router = null;
let base   = '/';

import Service from "@/controllers/Service.controller.js"
import StorageTable from "@/controllers/Storage.controller.js"
import Util from "@/controllers/Util.controller.js"

init();

function init() {
    if(globalConfigs.multilinguagem){
        let lang = 'lang' in localStorage ? localStorage.getItem('lang').substring(0,2) : 'pt'
        switch(lang){
            case 'pt':
                routes = pt;
                localStorage.setItem('lang', 'pt-BR')
                break;
            case 'es':
                routes = es;
                localStorage.setItem('lang', 'es-ES')
                break;
            case 'en':
                routes = en;
                localStorage.setItem('lang', 'en-US')
                break;
            default:
                routes = pt;
                localStorage.setItem('lang', 'pt-BR')
                break;
        }

        
        base =  `/${lang}/`
    
    } else {
        routes = pt
        localStorage.setItem('lang', 'pt-BR')
    }

    exit();
}

function exit() {

    const config = {
        base: base, 
        mode: 'history',
        routes
    }

    router = new VueRouter(config)

    router.beforeEach(async (to, from, next) => {

        const $storage = new StorageTable()
        const $http    = new Service()
        const $util    = new Util()
        const session  = $storage.getLocal('session') ? true: false
        let params     = $util.getSearchParams(document.location.href, 'isApp');
        let login      = $util.getSearchParams(document.location.href, 'isAppLogin');

        if(!('isApp' in sessionStorage) && params && login){
            sessionStorage.setItem('isApp', true)
            sessionStorage.setItem('isAppLogin', login)
            await $storage.setLocal('token', login)

            let uri = document.location.pathname
            
            const res = $storage.getLocal('token') ? await $http.get('DADOSCADASTRAIS') : false
            
            if(res) {
                if(res.underage && res.status_bond_responsible != 2 && to.name != 'SolicitaResponsavel'){
                    next({ name: "SolicitaResponsavel" });
                    return
                }
                await $storage.setLocal('session', res);
            }
            setTimeout(async () => {
                location.href = uri
            }, 1000)

            return
        } 

        if($storage.getLocal('session')){
            const dados = $storage.getLocal('session') 
            if(dados.underage && dados.status_bond_responsible != 2 && to.name != 'SolicitaResponsavel'){
                next({ name: "SolicitaResponsavel" });
                return
            }

            if(to.name == 'SolicitaResponsavel' && (!dados.underage || dados.status_bond_responsible == 2)){
                if(from.name){
                    next({ name: from.name });
                    return
                } else {
                    next({ name: "Hub" });
                    return
                }
            }
        }

        let AUTH = await $util.getListAuth()

        AUTH = AUTH.subtitulo

        let chaves = Object.keys(AUTH) 

        if(chaves.indexOf(to.name) != -1){
            if(AUTH[to.name].value && !session){
                next({ name: "Login", params: {rota: [to.name, to.params]}});
                return
            }
        } else {
            if(to.meta.requiresAuth && !session){
                next({ name: "Login", params: {rota: [to.name, to.params]}});
                return
            }
        }

        let features = await $util.getFeatures() 

        if(to.meta.parque){
            if(features.PARQUE === 'PARQUE'){
                next()
                return
            } else {
                next({ name: "Error" });
                return
            }
        }

        if(to.meta.gamification){
            if(features.GAMIFICATION === 'GAMIFICATION'){
                next()
                return
            } else {
                next({ name: "Error" });
                return
            }
        }

        next();

        document.title = to.meta.title;
        
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })

    })

    Vue.use(VueRouter)

}


export default router
