import Vue from "vue";
import Service from "@/controllers/Service.controller.js";
import StorageTable from "@/controllers/Storage.controller.js";
import Util from "@/controllers/Util.controller.js";
import Lang from "@/plugins/languagen/index.js";

export default class Menor extends StorageTable {

    constructor() {
        super();
        this.$util = new Util()
        this.$root = []
        this.$t_ = function (chave) {
            return new Lang()._traslater(chave);
        };
    }

    async vinculaResponsavel(dados){
        // FUNC: vinculaResponsavel()
        // CC: 
        const service = new Service()

        let res = await service.post("VINCULARESPONSAVEL",dados).catch(() =>{
            return false
        })

        if(res == true){
            let newSession = await service.get('DADOSCADASTRAIS')
            this.setLocal('session', newSession);
            return newSession
        }

        return res

    }

    async verificaMenor(idpessoa){
        // FUNC: verificaMenor()
        // CC: 
        const service = new Service()

        let res = await service.get("GETMENOR",idpessoa).catch(() =>{
            return false
        })

        if(res){
            let session =  this.getLocal('session');
            if(res.status_bond_responsible != session.status_bond_responsible){
                session.status_bond_responsible = res.status_bond_responsible
                this.setLocal('session', session);
                if(session.status_bond_responsible == 2){
                    return window.location.reload()
                }
            }
        }

        return res
    }

    async getQtdMenores(){
        // FUNC: getQtdMenores()
        // CC: 
        this.$root = await this.$util.getVue()
        const service = new Service()
        let session = this.getLocal('session');
        let idpessoa = session.idpessoa

        let res = await service.get("GETQTDMENORES", idpessoa).catch(() =>{
            return false
        })

        if(res && res > 0){
            this.$root.modalAvisoMenores = true
            this.$root.qtdMenor = res
        }

    }

    async getMenores(){
        // FUNC: getQtdMenores()
        // CC: 
        const service = new Service()
        let session = this.getLocal('session');
        let idpessoa = session.idpessoa

        let res = await service.get("GETMENORES", idpessoa).catch(() =>{
            return []
        })

        return res
    }

    async autorizaOuReprovaMenor(dados, aprova){
        // FUNC: autorizaOuReprovaMenor()
        // CC: 
        const service = new Service()

        if(!dados.id_person_responsible){
            this.$util.snackbar(this.$t_('RESPONSAVEL_NAO_IDENTIFICADO'), 'error');
            return false
        }
        if(!dados.id_person_minor){
            this.$util.snackbar(this.$t_('MENOR_NAO_IDENTIFICADO'), 'error');
            return false
        }

        if(aprova){
            dados.approved = true
        } else {
            dados.approved = false
        }

        let res = await service.post("APROVAMENOR", dados).catch(() =>{
            return false
        })

        return res
    }

    async incluirOuRemoverMenor(dados, inclui){
        // FUNC: incluirOuRemoverMenor()
        // CC: 
        const service = new Service()

        if(!dados.id_person_responsible){
            this.$util.snackbar(this.$t_('RESPONSAVEL_NAO_IDENTIFICADO'), 'error');
            return false
        }
        if(!dados.minor_document){
            this.$util.snackbar(this.$t_('MENOR_NAO_IDENTIFICADO'), 'error');
            return false
        }

        if(inclui){
            dados.include = true
        } else {
            dados.include = false
        }

        let res = await service.post("INCLUIRMENOR", dados).catch(() =>{
            return false
        })

        return res
    }

}
Vue.prototype.$menor = new Menor();
// window.$menor = new Menor();